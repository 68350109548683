<!--
 * @Author: your name
 * @Date: 2022-04-23 13:55:17
 * @LastEditTime: 2022-04-26 15:38:30
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \app\src\view\goods\inviteFriends\index.vue
-->
<template>
  <div class="share">
    <Nav></Nav>
    <div class="share_content">
      <div class="share_contentCase">
        <img
          :src="
            verConfig.url +
            'api/other/qrcode?text=' +
            encodeURIComponent(this.shareUrl)
          "
          alt=""
        />
        <div class="text_id">
          {{ $t("My.MemberInformation.Invitation") }}(ID):{{ sid }}
        </div>
        <div class="text_hb"></div>
        <!-- 邀请网站 -->
        <div class="url">
          <div class="url_text">
            {{ shareUrl }}
          </div>
        </div>
        <div class="url_btn" @click="copy">
          {{ $t("newAdd.copy") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/JNav.vue";
import { Toast } from "vant";
export default {
  components: { Nav },
  data() {
    return {
      shareUrl: JSON.parse(localStorage.getItem("public-share-item")).text,
      sid: JSON.parse(localStorage.getItem("userInfo")).id,
    };
  },
  methods: {
    copy() {
      this.$copyText(this.shareUrl).then(() => {
        Toast("success");
      });
    },
  },
  created() {
    // this.shareUrl = encodeURI(JSON.stringify(this.shareUrl))
    console.log(encodeURIComponent(this.shareUrl));
  },
};
</script>

<style lang="less" scoped>
@Color: #e23d6d;

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.share {
  // padding: 0 3vw;
  height: 100vh;
  // background: #13a9fe url(../../../assets/Img/myImg/site_27.35363508.png) no-repeat center -9.333vw;
  background: var(--bg);
  .share_content {
    position: relative;
    z-index: 3;
    width: 82%;
    padding: 2.4vw;
    margin: 42% 8% 0;
    border-radius: 2.667vw;
    // background-color: rgba(246, 153, 180, 0.35);
    background-color: rgba(255, 255, 255, 0.35);
    // box-shadow: 0 0 4vw rgb(0 0 0 / 20%);
    .flex();

    .share_contentCase {
      .flex(space-around, nowrap, center, column);
      background-color: #fff;
      border-radius: 2.667vw;
      width: 70%;
      padding: 10vw;
      height: 80vw;
    }

    .text_hb {
      height: 1px;
      width: 125%;
      background-color: rgba(0, 0, 0, 0.103);
    }

    .url {
      width: 100%;
      padding: 2.4vw 3.667vw 2.4vw 3.2vw;
      overflow: hidden;
      font: 3.2vw Arial, Helvetica, sans-serif;
      color: #666;
      border: 1px solid #f2f2f2;
      border-radius: 10.133vw;
      .flex(space-between);

      .url_text {
        // .text-overflow()
      }
    }

    .url_btn {
      background-color: @Color;
      // color: var(--textColor);
      color: var(--light);
      padding: 2vw 16vw;
      border-radius: 5vw;
    }
  }
}
</style>
